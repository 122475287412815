<template>
  <div>
    <div v-if="categories.length > 0" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title">All Categories</h3>
            <div class="card-toolbar">
              <router-link
                to="/categories/new"
                class="btn btn-mc font-weight-bolder font-size-sm"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  /> </span
                >Add New Category
              </router-link>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive">
              <table
                class="table table-head-custom table-head-bg table-vertical-center"
              >
                <thead>
                  <tr class="text-left text-uppercase">
                    <th style="min-width: 120px">Category</th>
                    <th style="min-width: 110px" class="pr-7 text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(category, key) in categories" :key="key">
                    <td class="pl-0">
                      <router-link
                        :to="'/categories/edit/' + category.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ category.title }}</router-link
                      >
                    </td>
                    <td class="pr-0 text-right">
                      <a
                        href="#"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Write.svg-->
                          <inline-svg
                            src="media/svg/icons/Communication/Write.svg"
                          />
                          <!--end::Svg Icon-->
                        </span>
                      </a>
                      <a
                        href="#"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-md">
                          <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/Trash.svg-->
                          <inline-svg src="media/svg/icons/General/Trash.svg" />
                          <!--end::Svg Icon-->
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center">
        <b-spinner
            variant="primary"
            type="grow"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'ListCategories',
  data() {
    return {
      categories: [],
      totalItems: null
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Categories', route: '/categories/list' }
    ])

    ApiService.get(`/post_categories`, `?order[createdAt]=desc`).then(
      ({ data }) => {
        this.totalItems = data['hydra:totalItems']
        this.categories = [...data['hydra:member']]
      }
    )
  }
}
</script>
